import { useTranslation } from 'react-i18next'
import { HashLink } from 'react-router-hash-link'


const InformationNext = ({ count = 0 }) => {
  const { t } = useTranslation()

  const informations = [
    {
      index: 1,
      name: "top5"
    },
    {
      index: 2,
      name: "questions"
    },
    // {
    //   index: 2,
    //   name: "bonuses-and-levels"
    // },
    {
      index: 3,
      name: "help-on-the-roads"
    },
    {
      index: 4,
      name: "parkings"
    },
    // {
    //   index: 5,
    //   name: "electric"
    // }
  ]

  return (
    <div className="information-content__next">
      {informations.slice(-count).map((info) => {
        return (
          <HashLink to={`/faq/${info.name}#title`} className="information-block" key={`${info.name}_${info.index}`}>
            <h2 className="information-title"><span>{info.index}.</span> {t(`faq.${info.name}.title`)}</h2>
            <div className="information-arrow"></div>
          </HashLink>
        )
      })}
    </div>
  )
}
export default InformationNext