import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { handleEvent } from '../../redux/actions'

import Buttons from '../../components/Buttons/Buttons'
import Spinner from '../../components/Spinner/Spinner'

import WithService from '../../services/hoc'
import { useCookie } from '../../utils/cookie.js'

import './login.sass'


const Login = (props) => {
  const { t } = useTranslation()

  const [login, setLogin] = useState('') //+79114635484 //+37126085421
  const [password, setPassword] = useState('') //00000 //44898
  const [showError, setShowError] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const hasCookies = useSelector((state) => state.cookies)
  const { eventToken } = useSelector((state) => state.event)
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = `${t("login.title")} - CARGURU`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  const handleSubmit = e => {
    e.preventDefault()
    setShowSpinner(true)

    const { Service } = props
    Service.postLogin({ login, password })
      .then(({ response }) => {
        let date = new Date(Date.now() + response.expires_in * 1000)

        if (hasCookies) {
          useCookie.set('eventToken', response.access_token, { 'expires': date })

          useCookie.delete('eventPromo')
          useCookie.delete('eventExpiredAt')
        }
        dispatch(handleEvent('eventToken', response.access_token))

        setRedirect(true)
      })
      .catch((error) => {
        console.error(error)
        setShowError(true)
        setPassword('')
      })
      .finally(() => {
        setShowSpinner(false)
      })
  }

  useEffect(() => {
    if (hasCookies && useCookie.get('eventToken')) {
      dispatch(handleEvent('eventToken', useCookie.get('eventToken')))
      setRedirect(true)
    } else if (eventToken) {
      hasCookies && useCookie.set('eventToken', eventToken)
      setRedirect(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (redirect) return <Redirect to='/event' />

  return (
    <section className="page login">
      <div className="container">
        <form onSubmit={handleSubmit} className="login-form">
          <div className="login-form__block">
            <input
              type="text"
              placeholder='+371 273 327 33'
              id="login"
              className='login-form__input login-form__input-active'
              required
              onChange={e => {
                // eslint-disable-next-line no-useless-escape
                setLogin(e.target.value.replace(/[^\+\d]/g, '').trim())
                setShowError(false)
              }}
              value={login}
            />
            <div className='login-form__label login-form__label-active'>{t("login.phone")}</div>
          </div>

          <div className="login-form__block">
            <input
              type="password"
              placeholder='******'
              id="password"
              className='login-form__input login-form__input-active'
              required
              autoComplete="on"
              onChange={e => {
                setPassword(e.target.value.trim())
                setShowError(false)
              }}
              value={password}
            />
            <div className='login-form__label login-form__label-active'>{t("login.password")}</div>
          </div>
          <span className={`login-form__error ${((showError) && `login-form__error-active`)}`}>{t("login.error")}</span>

          <div className='login-form__forgot'>
            <span>{t("login.forgot_password")}</span>

            <button type='submit' className='login-form__button'>
              {(showSpinner) ? (<Spinner onButton />) : (<>{t("button.login")}</>)}
            </button>
          </div>
        </form>

        <div className="login-info">
          <span className="login-info__title">{t("login.for_new_client")}</span>
          <Buttons onDark />
        </div>
      </div>
    </section>
  )
}

export default WithService()(Login)