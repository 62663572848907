import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// const fallbackLng = ['lv'];
const fallbackLng = (
  (navigator.cookieEnabled 
    && (
      localStorage.getItem('i18nextLng') === 'lv'
      || localStorage.getItem('i18nextLng') === 'ru'
      || localStorage.getItem('i18nextLng') === 'en'
    )
  )
  && [`${localStorage.getItem('i18nextLng')}`]
) || ['lv']
const availableLanguages = ['lv', 'ru', 'en'];

i18n
  // .use(ICU)
  // Подключение бэкенда i18next
  .use(Backend)
  // Автоматическое определение языка 
  .use(LanguageDetector)
  // модуль инициализации
  .use(initReactI18next)

  .init({
    // Стандартный язык
    fallbackLng,
    whitelist: availableLanguages,

    react: {
      // https://react.i18next.com/latest/trans-component#trans-props
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "span", "abbr", "b", "ol", "li"],
      transWrapTextNodes: ''
    },

    // debug: true,
    // Распознавание и кэширование языковых кук
    detection: {
      order: ['queryString', 'cookie'],
      cache: ['cookie'],
      cookiePath: '/',
      checkWhitelist: true  // определение языка
    },

    interpolation: {
      escapeValue: false
    }
  })

export default i18n;