import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import {
  Switch,
  Route,
  useLocation,
  Redirect
} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import InformationPrev from './components/InformationPrev'
import InformationItem from './components/InformationItem'
import InformationNext from './components/InformationNext'

import './information.sass'


const Information = () => {
  const location = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    document.title = `${t("nav.faq")} - CARGURU`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  useEffect(() => {
    if (location.hash === '')
      window.scrollTo(0, 0)
    else
      setTimeout(() => {
        const id = location.hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) element.scrollIntoView()
      }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="information">
      <Switch>

        <Route exact path='/faq'>

          <HashLink to='/faq/top5#title' id='top5' className="information-block">
            <h2 className="information-title">
              <span>1.</span> {t("faq.top5.title")}
            </h2>
            <div className="information-arrow"></div>
          </HashLink>

          <HashLink to='/faq/questions#title' id='questions' className="information-block">
            <h2 className="information-title">
              <span>2.</span> {t("faq.questions.title")}
            </h2>
            <div className="information-arrow"></div>
          </HashLink>

          <HashLink to='/faq/help-on-the-roads#title' id='help-on-the-roads' className="information-block">
            <h2 className="information-title">
              <span>3.</span> {t("faq.help-on-the-roads.title")}
            </h2>
            <div className="information-arrow"></div>
          </HashLink>

          <HashLink to='/faq/parkings#title' id='parkings' className="information-block">
            <h2 className="information-title">
              <span>4.</span> {t("faq.parkings.title")}
            </h2>
            <div className="information-arrow"></div>
          </HashLink>

          {/* <HashLink to='/faq/electric#title' id='electric' className="information-block">
            <h2 className="information-title"><span>5.</span> {t("faq.electric.title")}</h2>
            <div className="information-arrow"></div>
          </HashLink> */}
        </Route>

        <Route path={`/faq/top5`}>
          <InformationItem location={location.pathname} number={1} count={t("faq.top5.count")} />
          {/* <InformationNext count={4} /> */}
          <InformationNext count={3} />

          {/* <Redirect from="/faq/faq/***" to="/faq/faq" /> */}
        </Route>

        <Route path={`/faq/questions`}>
          <InformationPrev count={1} />
          <InformationItem location={location.pathname} number={2} count={t("faq.questions.count")} />
          {/* <InformationNext count={3} /> */}
          <InformationNext count={2} />

          {/* <Redirect from="/faq/faq/***" to="/faq/faq" /> */}
        </Route>

        {/* <Route path={`/information/bonuses-and-levels`}>
          <InformationPrev count={1}/>
          <InformationItem location={location.pathname} number={2} /> */}
        {/* <InformationNext count={3}/> */}
        {/* <InformationNext count={2}/> */}

        {/* <Redirect from="/faq/bonuses-and-levels/***" to="/faq/bonuses-and-levels" /> */}
        {/* </Route> */}

        <Route path={`/faq/help-on-the-roads`}>
          <InformationPrev count={2} />
          <InformationItem location={location.pathname} number={3} count={t("faq.help-on-the-roads.count")} />
          {/* <InformationNext count={2} /> */}
          <InformationNext count={1} />

          {/* <Redirect from="/faq/help-on-the-roads/***" to="/faq/help-on-the-roads" /> */}
        </Route>

        <Route path={`/faq/parkings`}>
          <InformationPrev count={3} />
          <InformationItem location={location.pathname} number={4} count={t("faq.parkings.count")} />
          {/* <InformationNext count={1} /> */}

          {/* <Redirect from="/faq/parkings/***" to="/faq/parkings"/> */}
        </Route>

        {/* <Route path={`/faq/electric`}>
          <InformationPrev count={4} />
          <InformationItem location={location.pathname} number={5} count={t("faq.electric.count")} />

        </Route> */}
        {/* <Redirect from="/faq/electric/***" to="/faq/electric" /> */}

        <Redirect from="/faq/***" to="/faq" />
      </Switch>
    </div>
  )
}

export default Information